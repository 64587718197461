<template>
  <div class="report-view task-time-sheet-report pb-5">
    <div
    v-if="exportLoader"
    class="report-loader-screen"
  >
    <div class="d-flex align-items-center justify-content-center h-100 overdlow-sheet">
      <img
        src="../../../public/loader.gif"
        alt=""
      >
    </div>
  </div>

    <div class="viewEdit ttrInfo" v-else>
      <!-- Start Date Picker Toggle -->
      <b-card class="d-flex">
        <div class="calendar justify-content-between mt-0">
          <p class="task-timesheet">
            Resource Availability Report
          </p>
        </div>
      </b-card>
      <!-- End Date Picker Toggle -->

      <!-- Start Filters & Export -->
      <div class="card-filter-section mt-0">
        <b-row>
          <b-col
            cols="8"
            lg="8"
            class="d-block d-lg-flex align-items-center justify-content-start flex-wrap"
          >
            <p class="filter-title">
              Filters:
            </p>
            <div class="w-lg-100">
              <custom-dropdown
                v-model="department_id"
                :options="filterDepartmentOptions"
                label="Department"
                multiple
              />
            </div>

            <div class="w-lg-100">
              <custom-dropdown
                v-model="division_id"
                :options="filterDivisionListOptions"
                label="Division"
                multiple
              />
            </div>

            <div class="w-lg-100">
              <custom-dropdown
                v-model="type_id"
                :options="filterEmployeeTypeListOptions"
                label="Employee Type"
                multiple
              />
            </div>

            <div
              v-if="userInfo && userInfo.role && userInfo.role == 'SA'"
              class="w-lg-100"
            >
              <custom-dropdown
                v-model="manage_by"
                :options="filterUserOptions"
                label="Manage By"
                multiple
              />
            </div>

            <div class="w-lg-100">
              <custom-dropdown
                v-model="allocation"
                :search-box="false"
                :options="allocations"
                hide-close
                label="Availability"
              />
            </div>
            <div class="w-lg-100">
              <custom-dropdown
                v-model="primary_skills"
                :options="$store.state.app.userSkills"
                multiple
                label="Primary Skill"
              />
            </div>
            <div class="w-lg-100">
              <custom-dropdown
                v-model="secondary_skills"
                :options="$store.state.app.userSkills"
                multiple
                label="Secondary Skill"
              />
            </div>

          </b-col>

          <b-col
            cols="4"
            lg="4"
            class="d-flex justify-content-end"
          >
            <button
              class="apply-filter filterRedesign mr-1"
              @click="applyFilter"
            >
              Apply Filter
            </button>
            <button
              class="apply-filter-clear filterRedesign mr-1"
              @click="clearFilter"
            >
              Clear
            </button>
            <button
              class="apply-filter filterRedesign mr-1"
              @click="isSchedularModalOpen = true"
            >
              Schedule
            </button>

            <b-dropdown
              id="dropdown-right"
              right
              text="Export"
              class="custom-drop-timesheet-report filterRedesign side-bar-clear"
            >
              <template #button-content>
                <div class="d-flex align-items-center">
                  <span class="mr-25 fw-600">Export</span>
                  <feather-icon
                    icon="ChevronDownIcon"
                    width="18"
                    height="18"
                  />
                </div>
              </template>
              <b-dropdown-item @click="exportData('PDF')">
                PDF
              </b-dropdown-item>
              <b-dropdown-item @click="exportData('CSV')"> XLSX
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </div>
      <!-- grid view  start shimmer-->
      <b-row
        v-if="loader"
        class="d-flex"
      >
        <b-col
          v-for="col in 4"
          :key="col"
          md="12"
          xl="3"
          lg="6"
        >
          <card />
        </b-col>
      </b-row>
      <!-- grid view  end shimmer-->
      <!-- Card Design Here -->
      <ResourceAvailabilityCard
        v-if="!showNoDataFound && !loader"
        :data="data"
      />
      <div
        v-if="showNoDataFound && !loader"
        class="w-100"
      >
        <img
          src="@/assets/images/nodatafound/nodata.svg"
          alt="no-data"
          class="no-data-img"
        >
      </div>
    </div>

    <AddEditScheduledReport
      :data="null"
      :is-schedular-modal-open-prop="isSchedularModalOpen"
      :filter="filter"
      @updateSchedularModalOpen="isSchedularModalOpen = $event"
    />
  </div>
</template>

<script>
import ListLoader from '@/components/loaders/table-loading.vue'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import * as moment from 'moment/moment'
import AddEditScheduledReport from './scheduleReports/AddEditScheduledReport.vue'
import ResourceAvailabilityCard from './scheduleReports/ResourceAvailabilityCard.vue'
import ResourceAvailabilityAccordion from './scheduleReports/ResourceAvailabilityAccordion.vue'
import Card from '../loaders/resource-avaiblity-loader.vue'

export default {
  name: 'HeaderBlock',
  components: {
    ListLoader,
    customDropdown,
    AddEditScheduledReport,
    ResourceAvailabilityCard,
    ResourceAvailabilityAccordion,
    Card,
  },
  data() {
    return {
      loader: false,
      exportLoader:false,
      occurrence: null,
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          thStyle: { width: '400px' },
          tdStyle: { width: '400px' },
        },
        {
          key: 'occurence',
          label: 'Occurence',
          sortable: true,
          thStyle: { width: '400px' },
          tdStyle: { width: '400px', textAlign: 'start' },
        },
        {
          key: 'last_sent',
          label: 'Last Sent',
          sortable: true,
          thStyle: { width: '400px' },
          tdStyle: { width: '400px', textAlign: 'start' },
        },
        {
          key: 'is_active',
          label: 'Active',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          thStyle: { width: '100px' },
          tdStyle: { width: '100px' },
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      items: [],
      createdByList: [],
      users: [],
      isSchedularModalOpen: false,
      data: {},
      allocations: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'available',
          label: 'Available',
        },
        {
          value: 'parttime',
          label: 'Part Time',
        },
        {
          value: 'fulltime',
          label: 'Full Time',
        },
        {
          value: 'is_pool',
          label: 'Pool',
        },
      ],
      allocation: 'all',
      manage_by: [],
      type_id: [],
      division_id: [],
      department_id: [],
      primary_skills: [],
      secondary_skills: [],
      filter: {
        availability: null,
        manage_by: [],
        type_id: [],
        division_id: [],
        department_id: [],
        primary_skills: [],
        secondary_skills: [],
      },
    }
  },
  computed: {
    /* created by user option list */
    filterUserOptions() {
      const { usersList } = this.$store.state.app
      const manageByIDs = new Set(usersList.map(user => user.manage_by))

      // Filter usersList based on manageByIDs
      const filteredList = usersList.filter(user => manageByIDs.has(user.id) || user.is_resource_managing)

      return filteredList
    },

    /* created by user option list */
    filterDepartmentOptions() {
      return this.$store.state.app.departmentList.map(department => ({
        value: department.id,
        label: department.name.trim(),
      }))
    },

    /* created by user option list */
    filterEmployeeTypeListOptions() {
      return this.$store.state.app.employeeTypeList.map(employeeType => ({
        value: employeeType.id,
        label: employeeType.name,
      }))
    },

    /* created by user option list */
    filterDivisionListOptions() {
      return this.$store.state.app.divisionList.map(division => ({
        value: division.id,
        label: division.name,
      }))
    },

    /* created by user option list */
    showNoDataFound() {
      let isShow = true
      if (this.data) {
        Object.keys(this.data).forEach(element => {
          if (this.data[element].count > 0) {
            isShow = false
          }
        })
        return isShow
      }
      return true
    },
  },
  watch: {
    allocation(nv) {
      if (nv) {
        this.filter.availability = nv
      } else {
        this.allocation = 'all'
      }
    },
    manage_by(nv) {
      this.filter.manage_by = nv
    },
    type_id(nv) {
      this.filter.type_id = nv
    },
    division_id(nv) {
      this.filter.division_id = nv
    },
    department_id(nv) {
      this.filter.department_id = nv
    },
    primary_skills(nv) {
      this.filter.primary_skills = nv
    },
    secondary_skills(nv) {
      this.filter.secondary_skills = nv
    },
  },
  mounted() {
    /* if user list not in store then call user list api */
    if (
      this.$store.state.app.usersList
      && this.$store.state.app.usersList.length === 0
    ) {
      this.userList()
    }
    /* if department list not in store then call department list api */
    if (
      !this.$store.state.app.departmentList
      || !this.$store.state.app.departmentList.length
    ) {
      this.departmentList()
    }
    /* if division list not in store then call division list api */
    if (
      !this.$store.state.app.divisionList
      || !this.$store.state.app.divisionList.length
    ) {
      this.divisionList()
    }
    /* if employee list not in store then call employee list api */
    if (
      !this.$store.state.app.employeeTypeList
      || !this.$store.state.app.employeeTypeList.length
    ) {
      this.employeeTypeList()
    }
    /* if employee list not in store then call employee list api */
    if (this.$store.state.app.userSkills.length == 0) {
      this.getPrimarySkillData()
    }

    this.getReportData()
  },
  methods: {
    applyFilter() {
      this.getReportData()
    },
    clearFilter() {
      this.allocation = null
      this.manage_by = []
      this.type_id = []
      this.division_id = []
      this.department_id = []
      this.primary_skills = []
      this.secondary_skills = []
      this.applyFilter()
    },
    async getReportData() {
      this.loader = true
      const input = {
        month: moment().startOf('month').format('MM'),
        year: moment().startOf('month').format('YYYY'),
        allocation: this.allocation == 'all' ? null : this.allocation,
        manage_by: this.manage_by,
        type_id: this.type_id,
        division_id: this.division_id,
        department_id: this.department_id,
        primary_skills: this.primary_skills,
        secondary_skills: this.secondary_skills,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/reports/resource-availability-report',
        input,
        false,
      )
      if (response && response.data) {
        this.data = response.data
      }
      this.loader = false
    },
    async exportData(type) {
      this.exportLoader = true

      // Create URLSearchParams object with compulsory parameters
      const params = new URLSearchParams({
        user_id: this.userInfo.id,
        role: this.userInfo.role,
        file_type: type,
        month: moment().startOf('month').format('MM'),
        year: moment().startOf('month').format('YYYY'),
      })

      // Add optional parameters only if they have values
      if (this.allocation && this.allocation !== 'all') {
        params.append('allocation', this.allocation)
      }

      // Append array parameters if they have values
      const arrayParams = {
        manage_by: this.manage_by,
        type_id: this.type_id,
        division_id: this.division_id,
        department_id: this.department_id,
        primary_skills: this.primary_skills,
        secondary_skills: this.secondary_skills,
      }

      for (const [key, value] of Object.entries(arrayParams)) {
        if (Array.isArray(value) && value.length > 0) {
          value.forEach(element => params.append(`${key}[]`, element))
        }
      }

      // Construct the final URL
      const URL = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/reports/resource-availability-report-export?${params.toString()}`

      const fileName = `ResourceAvailabilityReport.${type}`

      try {
        const response = await this.$axios({
          url: URL,
          method: 'GET',
          responseType: 'blob',
        })

        if (type === 'PDF') {
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' }),
          )
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', fileName)
          document.body.appendChild(fileLink)
          fileLink.click()
          document.body.removeChild(fileLink)
        } else {
          location.href = URL
        }
      } catch (error) {
        console.error('Error exporting data:', error)
      } finally {
        this.exportLoader = false
      }
    },

  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables/variables";

.accordion {
  .title-button {
    width: 100%;
  }

  .not-collapsed {
    .collapseicon {
      transform: rotate(90deg);
      transition: 0.3s ease-in-out;
      color: $white;
      height: 16px;
      width: 16px;
    }

    .featherspan {
      border: 0px solid transparent;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-color;
    }

    .sub-featherspan {
      @extend .featherspan;
      border: 0px;
      background-color: $white;
    }

    .sub-collapseicon {
      @extend .collapseicon;
      transform: rotate(180deg);
      color: $primary-color;
      height: 24px;
      width: 24px;
    }
  }

  .collapsed {
    .collapseicon {
      transform: rotate(0deg);
      transition: 0.3s ease-in-out;
      color: $primary-color;
      height: 16px;
      width: 16px;
    }

    .featherspan {
      border: 3px solid $primary-color;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sub-featherspan {
      @extend .featherspan;
      border: 2px;
    }

    .sub-collapseicon {
      @extend .collapseicon;
      color: $primary-color;
      height: 24px;
      width: 24px;
    }
  }

  .resource-title {
    margin-left: 20px;
    font-size: 2.25 * $rem;
    font-weight: $fw600;
    color: $primary-color;
    text-transform: capitalize;
    width: 250px;
  }

  .resource-des {
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid $input-outline;
    color: $logo-color !important;
    background-color: transparent;

    &:focus-visible {
      outline: $input-outline;
    }
  }

  .resource-item-labal {
    font-size: 2.25 * $rem;
    font-weight: $fw700;
    color: #555;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .resource-item {
    color: $font-color;
    font-size: 2 * $rem;
    line-height: 26px;
    font-weight: $fw500;
  }

  .resource-header {
    padding: 10px 20px 10px 20px;
  }
}

.role {
  margin: 10px 0;
  padding-left: 20px;
  border-radius: 5px;

  .role-header {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .names {
    margin-left: 20px;

    .role-type {
      margin-bottom: 5px;
      font-weight: bold;
    }

    ul {
      list-style-type: none;
      padding-left: 20px;
      margin-top: 5px;

      li {
        margin-bottom: 3px;
      }
    }
  }
}

.custom-drop-timesheet-report {
  background-color:#f97753;
  &.side-bar-clear {
    padding: 10px 20px;
  }

  .dropdown-toggle {
    color: #ffffff !important;
  }

  .dropdown-item:hover,
  [dir] .dropdown-item:focus {
    background-color: rgba(249, 119, 83, 0.12) !important;
    color: #f97753;
  }

  .dropdown-menu {
    border-radius: 0.358rem;
    transform: scale(1, 0);
    box-shadow: 0 5px 25px rgb(34 41 47 / 10%) !important;
    background-color: #fff !important;
    left: 0px !important;
  }
}
.report-loader-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 1080;

  img {
    width: 100px;
  }

  .overdlow-sheet {
    overflow-y: hidden;
  }
}
</style>
