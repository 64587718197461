<template>
  <div>
    <div
      v-for="(item, index) in Object.keys(data)"
      v-if="data[item].userlist.length"
      :key="index"
      class="accordion"
      role="tablist"
    >
      <b-card
        no-body
        class="mb-1"
      >
        <b-card-header
          header-tag="header"
          class="d-flex resource-header"
          role="tab"
        >
          <div>
            <div class="d-flex align-items-center justify-content-between">
              <b-button
                v-b-toggle="`collapse-${index}`"
                block
                class="title-button"
              >
                <span class="featherspan">
                  <b-icon
                    icon="Caret-right-fill"
                    size="50"
                    class="collapseicon"
                  />
                </span>
              </b-button>
              <div v-b-toggle="`collapse-${index}`">
                <p class="resource-title">
                  {{ item }}
                </p>
              </div>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          :id="`collapse-${index}`"
          ref="collapse"
          accordion="my-accordion"
          role="tabpanel"
        >
          <div class="m-1">
            <b-table
              responsive
              :items="data[item].userlist"
              :fields="fields"
              show-empty
              class="scrollable-element tableDesigns mastertblColor non-project-table"
            >
              <template #cell(primary_skills)="data">
                <span
                  v-for="(skill,index) in tagsArray(data.item.primary_skills) "
                  :key="index"
                  class="mr-1"
                >
                  <b-badge

                    v-if="index < 2"
                    pill
                    :variant="skillBackground()"
                  >
                    {{
                      skill
                    }}
                  </b-badge>
                </span>
                <span
                  v-if="tagsArray(data.item.primary_skills) > 2"
                  :key="index"
                  class="mr-1"
                >
                  {{ tagsArray(data.item.primary_skills).length - 2 }}
                </span>
                <span v-if="!data.item.primary_skills">- </span>
              </template>
              <template #cell(secondary_skills)="data">

                <span
                  v-for="(skill,index) in tagsArray(data.item.secondary_skills)"
                  :key="index"
                  class="mr-1"
                >
                  <b-badge

                    v-if="index < 2"
                    pill
                    :variant="skillBackground()"
                  >
                    {{
                      skill
                    }}
                  </b-badge>
                </span>
                <span
                  v-if="tagsArray(data.item.secondary_skills) > 2"
                  :key="index"
                  class="mr-1"
                >
                  {{ tagsArray(data.item.secondary_skills).length - 2 }}
                </span>
                <span v-if="!data.item.secondary_skills">- </span>
              </template>

              <template #cell(total_previous_experience)="data">
                {{ data.item.total_previous_experience ?data.item.total_previous_experience: '-' }}
              </template>
              <template #cell(department)="data">
                {{ data.item.department ?data.item.department: '-' }}
              </template>
              <template #cell(division)="data">
                {{ data.item.division ?data.item.division : '-' }}
              </template>
              <template #cell(employeeType)="data">
                {{ data.item.employeeType ? data.item.employeeType: '-' }}
              </template>
            </b-table>
          </div>

        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ResourceAvailabilityAccordion',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          thStyle: { width: '250px' },
          tdStyle: { width: '250px' },
        },
        {
          key: 'total_previous_experience',
          label: 'Experience',
          sortable: false,
          thStyle: { width: '100px' },
          tdStyle: { width: '100px' },
        },
        {
          key: 'primary_skills',
          label: 'Primary Skills',
          sortable: false,
          thStyle: { width: '250px' },
          tdStyle: { width: '250px' },
        },
        {
          key: 'secondary_skills',
          label: 'Secondary Skills',
          sortable: false,
          thStyle: { width: '250px' },
          tdStyle: { width: '250px' },
        },
        {
          key: 'department',
          label: 'Department',
          sortable: false,
          thStyle: { width: '250px' },
          tdStyle: { width: '250px' },
        },
        {
          key: 'division',
          label: 'Division',
          sortable: false,
          thStyle: { width: '250px' },
          tdStyle: { width: '250px' },
        },
        {
          key: 'employeeType',
          label: 'Employee Type',
          sortable: false,
          thStyle: { width: '250px' },
          tdStyle: { width: '250px' },
        },

      ],
    }
  },
  methods: {
  },
}
</script>
<style lang="scss">
@import "../../../assets/scss/variables/variables";
.accordion {
  .title-button {
    width: 100%;
  }
  .not-collapsed {
    .collapseicon {
      transform: rotate(90deg);
      transition: 0.3s ease-in-out;
      color: $white;
      height: 16px;
      width: 16px;
    }
    .featherspan {
      border: 0px solid transparent;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-color;
    }
    .sub-featherspan {
      @extend .featherspan;
      border: 0px;
      background-color: $white;
    }
    .sub-collapseicon {
      @extend .collapseicon;
      transform: rotate(180deg);
      color: $primary-color;
      height: 24px;
      width: 24px;
    }
  }
  .collapsed {
    .collapseicon {
      transform: rotate(0deg);
      transition: 0.3s ease-in-out;
      color: $primary-color;
      height: 16px;
      width: 16px;
    }
    .featherspan {
      border: 3px solid $primary-color;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sub-featherspan {
      @extend .featherspan;
      border: 2px;
    }
    .sub-collapseicon {
      @extend .collapseicon;
      color: $primary-color;
      height: 24px;
      width: 24px;
    }
  }
  .resource-title {
    margin-left: 20px;
    font-size: 2.25 * $rem;
    font-weight: $fw600;
    color: $primary-color;
    text-transform: capitalize;
    width: 250px;
  }
  .resource-des {
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid $input-outline;
    color: $logo-color !important;
    background-color: transparent;
    &:focus-visible {
      outline: $input-outline;
    }
  }
  .resource-item-labal {
    font-size: 2.25 * $rem;
    font-weight: $fw700;
    color: #555;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .resource-item {
    color: $font-color;
    font-size: 2 * $rem;
    line-height: 26px;
    font-weight: $fw500;
  }
  .resource-header {
    padding: 10px 20px 10px 20px;
  }
}
.role {
  margin: 10px 0;
  padding-left: 20px;
  border-radius: 5px;
  .role-header {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .names {
    margin-left: 20px;
    .role-type {
      margin-bottom: 5px;
      font-weight: bold;
    }
    ul {
      list-style-type: none;
      padding-left: 20px;
      margin-top: 5px;
      li {
        margin-bottom: 3px;
      }
    }
  }
}
.department-name {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.8;
}
.employee-type {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
}
.employee-name {
  color: #000;
  font-size: 14px;
  opacity: 0.9;
  font-weight: 400;
}
</style>
