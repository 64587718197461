<template>
  <div>
    <b-card class="newproject-card projectListinfo">
      <div class="projectListBody">
        <div class="mb-2">
          <b-row class="">
            <b-col
              cols="6"
              class="pr-0"
            >
              <p class="newproject-scop-member">
                <text-shimmer class="cardShimmer" /></p>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <p class="newproject-scop-member">
                <text-shimmer class="cardShimmer" /></p>
            </b-col>
          </b-row>
        </div>
        <div class="mb-2">
          <b-row class="newproject-date-section">
            <b-col
              cols="6"
              class="pr-0"
            >
              <p class="newproject-scop-member">
                <text-shimmer class="cardShimmer" /></p>
              <p class="newproject-timeline-date">
                <text-shimmer class="mt-25 cardShimmer" />
              </p>
            </b-col>
            <b-col cols="6">
              <p class="newproject-scop-member">
                <text-shimmer class="cardShimmer" /></p>
              <p class="newproject-timeline-date">
                <text-shimmer class="mt-25 cardShimmer" />
              </p>
            </b-col>
          </b-row>
        </div>
        <div class="mb-2">
          <b-row class="newproject-date-section">
            <b-col
              cols="6"
              class="pr-0"
            >
              <p class="newproject-scop-member">
                <text-shimmer class="cardShimmer" /></p>
              <p class="newproject-timeline-date">
                <text-shimmer class="mt-25 cardShimmer" />
              </p>
            </b-col>
            <b-col cols="6">
              <p class="newproject-scop-member">
                <text-shimmer class="cardShimmer" /></p>
              <p class="newproject-timeline-date">
                <text-shimmer class="mt-25 cardShimmer" />
              </p>
            </b-col>
          </b-row>
        </div>
        <div class="mb-2">
          <b-row class="newproject-date-section">
            <b-col
              cols="6"
              class="pr-0"
            >
              <p class="newproject-scop-member">
                <text-shimmer class="cardShimmer" /></p>
              <p class="newproject-timeline-date">
                <text-shimmer class="mt-25 cardShimmer" />
              </p>
            </b-col>
            <b-col cols="6">
              <p class="newproject-scop-member">
                <text-shimmer class="cardShimmer" /></p>
              <p class="newproject-timeline-date">
                <text-shimmer class="mt-25 cardShimmer" />
              </p>
            </b-col>
          </b-row>
        </div>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  VBTooltip,
  BBadge,
  BAvatar,
} from 'bootstrap-vue'
import TextShimmer from '../../views/shimmer/TextShimmer.vue'
import ShortText from '../../views/shimmer/shortText.vue'
import LongTextShimmer from '../../views/shimmer/LongTextShimmer.vue'
import CircleShimmerVue from '../../views/shimmer/CircleShimmer.vue'

export default {
  name: 'NewProjectCard',
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    TextShimmer,
    ShortText,
    LongTextShimmer,
    CircleShimmerVue,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
}
</script>

  <style>
  @import url("../../assets/scss/component-css/projectcards.css");

  .cardShimmer .loader {width: 100%;min-width: 100%;max-width: 100%;}
  </style>
